<template>

  <vs-popup :title="getTitle" :active.sync="popupActiveLocal">
    <div class="flex flex-col gap-10 p-5">
      <h3 class="text-center">¿Está seguro que desea {{ $props.tryingToAccept ? 'aprobar' : 'rechazar' }} este aviso de recarga?</h3>
      <div class="flex flex-col gap-3">
        <h6>{{ $props.tryingToAccept ? 'Motivo de aprobación (Opcional)' : 'Motivo de Rechazo' }}</h6>
        <vs-input
          class="w-full"
          v-model="reference"
          name="reference"
          v-validate="`${$props.tryingToAccept ? '' : 'required|'}max:${MAX_CHARACTERS}`"
          :data-vv-as="$props.tryingToAccept ? 'Motivo de aprobación' : 'Motivo de Rechazo'"
        />
        <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first('reference') }}</span>
      </div>
      <div class="flex justify-center gap-6">
        <vs-button class="bg-transparent text-primary font-mediumww" @click="onRemovePopup">
          Cancelar
        </vs-button>
        <vs-button
          :loading="true"
          :disabled="isLoading || !isFormValid"
          @click="onAction()"
        >
          {{ $props.tryingToAccept ? 'Aceptar' : 'Rechazar' }}
        </vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
  import reportModule from '@/store/report/moduleReport.js'

  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      popupActive: {
        type: Boolean,
        required: true
      },
      tryingToAccept: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        reference: '',
        MAX_CHARACTERS: 80,
        isLoading: false,
        title: ''
      }
    },
    methods: {
      onRemovePopup() {
        this.$emit('updatePopupActive')
      },
      async onAction() {
        const achData = this.$props.data
        this.isLoading = true
        try {
          achData.reference = this.reference
          if (this.$props.tryingToAccept) {
            await this.$store.dispatch('reportModule/approveNoticesRecharge', achData)
          } else {
            await this.$store.dispatch('reportModule/rejectNoticesRecharge', achData)
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.isLoading = false
          this.onRemovePopup()
        }

      }
    },
    watch: {
      popupActive(val) {
        if (!val) return
        this.reference = ''
        this.isLoading = false
      }
    },
    computed: {
      popupActiveLocal: {
        get() {
          return JSON.parse(this.popupActive)
        },
        set() {
          this.$emit('updatePopupActive')
          this.reference = ''
        }
      },
      item() {
        return this.data
      },
      getTitle() {
        const tryingToAccept = this.$props.tryingToAccept
        const data = this.$props.data
        if (tryingToAccept) return `Aprobar recarga #${data.id}`
        return `Rechazar recarga #${data.id}`
      },
      isFormValid() {
        return !this.errors.any()
      }
    },
    beforeCreate() {
      if (!reportModule.isRegistered) {
        this.$store.registerModule("reportModule", reportModule);
        reportModule.isRegistered = true;
      }
    },
  }
</script>
