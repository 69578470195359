<template>
  <div class="vx-card p-6 mt-6">
    <h5 style="margin-bottom:9px">Avisos de Recarga</h5>
    <div slot="no-body" class="tabs-container px-6 pt-6">
      <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
        <vs-tab label="Pendientes" icon-pack="feather" icon="icon-layers">
          <div class="tab-text">
            <pending-table @updateSearchQuery="updateSearchQuery" />
          </div>
        </vs-tab>
        <vs-tab label="Procesadas" icon-pack="feather" icon="icon-archive" @click="searchQuery = ''">
          <div class="tab-text">
            <processed-table :searchQuery="searchQuery" />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import PendingTable from './notices-recharge/NoticesRechargePendingRequests.vue'
import ProcessedTable from './notices-recharge/NoticesRechargeProcessedRequests.vue'

export default {
  components: {
    PendingTable,
    ProcessedTable
  },
  data() {
    return {
      activeTab: 0,
      searchQuery: ""
    }
  },
  methods: {
    updateSearchQuery(q) {
      this.searchQuery = q
      this.activeTab = 1
    },
  }
}
</script>