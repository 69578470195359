<template>
  <div>
    <!-- TABLE ACTION ROW -->
    <div class="flex flex-wrap justify-between items-center">
      <!-- ITEMS PER PAGE -->
      <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
              currentPage * paginationPageSize - (paginationPageSize - 1)
            }}
              -
              {{
                pendingData.length - currentPage * paginationPageSize > 0
                ? currentPage * paginationPageSize
                : pendingData.length
              }}
              de {{ pendingData.length }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
              <span>50</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
              <span>100</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
              <span>150</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
      <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
        <vs-input
          class="mb-4 md:mb-0 mr-4"
          v-model="searchQuery"
          placeholder="Buscar..."
          @input="updateSearchQuery"
        />
        <vs-button
          class="mb-4 md:mb-0"
          @click="gridApi.exportDataAsCsv()"
        >
          Exportar
        </vs-button>
      </div>

    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="pendingData"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :suppressHorizontalScroll="false"
      :enableRtl="$vs.rtl"
      :modules="modules"
      @first-data-rendered="onFirstDataRendered"
    >
    </ag-grid-vue>
    <!-- <ag-grid-vue
      :modules="modules"
      class="ag-theme-material w-100 ag-grid-table total"
      style="height: 60px; flex: none;"
      :gridOptions="bottomGridOptions"
      :columnDefs="columnDefs"
      :rowData="mockData"
      :headerHeight="0"
      :floatingFiltersHeight="0"
    >
    </ag-grid-vue> -->
    <vs-pagination
      :total="totalPages"
      :max="7"
      v-model="currentPage"
    />
    <notices-recharge-modal
      :popupActive="popupActive"
      :data="popupData"
      :tryingToAccept="tryingToAccept"
      @updatePopupActive="updatePopupActive(false)"
    />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActionNoticesRechargePending from './CellRendererActionNoticesRechargePending.vue'
import reportModule from '@/store/report/moduleReport.js'
import NoticesRechargeModal from './NoticesRechargeModal.vue'
// Store Modules

export default {
  components: {
    VuePerfectScrollbar,
    CellRendererActionNoticesRechargePending,
    AgGridVue,
    NoticesRechargeModal
  },
  data() {
    return {
      modules: AllCommunityModules,
      gridApi: null,
      tryingToAccept: false,
      // Modal
      popupData: {},
      popupActive: false,

      //Table
      searchQuery: "",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      gridOptions: {
        alignedGrids: [],
        suppressHorizontalScroll: true,
        context: {}
      },
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          width: 75,
          filter: true,
        },
        {
          headerName: "Nombre y Apellido",
          field: "full_name",
          width: 180,
          filter: true
        },
        {
          headerName: "Fecha y Hora de Aviso",
          field: "register_date_time",
          width: 140,
          filter: true,
          valueFormatter: params => this.transformDate(params.data.register_date_time)
        },
        {
          headerName: "Nombre del Banco",
          field: "ach_bank",
          width: 140,
          filter: true,
        },
        {
          headerName: "Responsable de la Cuenta",
          field: "responsible",
          width: 140,
          filter: true,
        },
        {
          headerName: "Cuenta Bancaria",
          field: "ach_account_number",
          width: 140,
          filter: true,
        },
        {
          headerName: "Fecha y Hora de Recarga",
          field: "recharge_date",
          width: 140,
          filter: true,
          valueFormatter: params => this.transformDate(params.data.recharge_date)
        },
        {
          headerName: "Monto",
          field: "amount",
          width: 150,
          filter: true,
          valueFormatter: params => `$ ${params.data.amount}`
        },
        {
          headerName: 'Acción',
          filter: true,
          cellRendererFramework: 'CellRendererActionNoticesRechargePending',
          cellRendererParams: {
            onShowPopup: this.onShowPopup.bind(this),
          }
        }
      ],
      // Bottom Summary Row
      bottomGridOptions: {
        alignedGrids: [],
        defaultColDef: {
          editable: false,
          sortable: true,
          resizable: true,
          filter: true,
          flex: 1,
          floatingFiltersHeight: 0
        }
      },
    }
  },
  methods: {
    onShowPopup(data, isTryingToAccept) {
      this.popupData = data
      this.popupActive = true
      this.tryingToAccept = isTryingToAccept
    },
    updatePopupActive(value) {
      this.popupActive = value
      this.tryingToAccept = false
      this.popupData = {}
    },
    setPaginationSize(value) {
      if (typeof value !== 'number') return
      this.paginationPageSize = value
    },
    getList() {
      const params = new URLSearchParams()
      // 0 = pending
      params.set('checked', '0')
      this.$store.dispatch('reportModule/getPendingNoticesRecharge', params.toString())
    },
    onFirstDataRendered: function() {
      this.gridColumnApi.autoSizeAllColumns();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    transformDate(dateToTransform) {
      const date = new Date(dateToTransform)
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      const hours = date.getHours()
      const minutes = date.getMinutes()
      // const hourFormat = hours >= 12 ? 'pm' : 'am'

      return `${day}/${month + 1}/${year} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}` // ${hourFormat}
    }
  },

  created() {
    this.getList()
  },
  computed: {
    paginationPageSize() {
      if (!this.gridApi) return 20
      return this.gridApi.paginationGetPageSize()
    },
    totalPages() {
      if (!this.gridApi) return 1
      return this.gridApi.paginationGetTotalPages()
    },
    currentPage: {
      get() {
        if (!this.gridApi) return 1
        return this.gridApi.paginationGetCurrentPage() + 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    pendingData() {
      return this.$store.state.reportModule.pendingNoticesRecharge
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);

    this.gridOptions.context = {
      componentParent: this
    };
  },
  beforeCreate() {
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }
  },
}
</script>

<style>
.ag-header-cell-label .ag-header-cell-text {
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}

.ag-grid-table.total .ag-row {
  font-weight: 700;
}

.ag-grid-table.total .ag-header {
  border: none;
}
</style>
