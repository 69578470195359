<template>
  <div class="flex items-center gap-2 h-full">
    <vx-tooltip class="flex h-full" text="Aprobar Recarga">
      <button
        type="button"
        class="cursor-pointer flex items-center justify-center px-4 bg-transparent border-0"
        @click="onAccept()"
      >
        <feather-icon
          icon="CheckIcon"
          svgClasses="h-5 w-5 cursor-pointer stroke-current text-success"
        />
      </button>

    </vx-tooltip>
    <vx-tooltip
      class="flex h-full"
      text="Rechazar Recarga"
    >
      <button
        type="button"
        class="cursor-pointer flex items-center justify-center px-4 bg-transparent border-0"
        @click="onCancel()"
      >
        <feather-icon
          icon="XIcon"
          svgClasses="h-5 w-5 stroke-current text-danger"
        />
      </button>
    </vx-tooltip>
  </div>
</template>

<script>
    export default {
      name: 'CellRendererActionNoticesRechargePending',
      methods: {
        onAccept() {
          this.params.onShowPopup(this.params.data, true)
        },
        onCancel() {
          this.params.onShowPopup(this.params.data, false)
        }
      },
    }
</script>
